.player-page {
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
  height: 100%;
}
.player-page .player-page-placeholder {
  padding: 0;
}
.player-page-overlay {
  position: fixed;
  top: 52px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #121212;
  opacity: 0.5;
  z-index: 4;
}
.player-page__tablist {
  margin-top: 12px;
  border-bottom: 1px solid #242424;
}
.player-page__tab {
  display: inline-block;
  padding: 16px 8px;
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
  border-bottom: 2px solid #53D337;
}
.player-page-header {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  border-radius: 16px;
  overflow: hidden;
  background-color: #242424;
}
.player-page-header__info {
  padding-top: 30px;
  padding-left: 40px;
  padding-bottom: 30px;
  font-family: 'Saira Condensed';
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
.player-page-header__team-name {
  margin: 0;
  color: #c5c5c5;
  font-size: 14px;
  text-transform: uppercase;
}
.player-page-header__name {
  margin: 0;
  color: #fff;
  font-size: 34px;
  line-height: 38px;
  text-transform: uppercase;
}
.player-page-header__position {
  display: inline-flex;
  margin-top: 12px;
  padding: 7px 14px;
  gap: 0 3px;
  border-radius: 80px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}
.player-page-header__position-name {
  margin: 0;
  color: #fff;
}
.player-page-header__position-text {
  margin: 0;
  color: #ababab;
}
.player-page-header__info-placeholder {
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 30px;
}
.player-page-header__info-placeholder-inner {
  margin: 0;
  background-color: #373737;
  opacity: 0.7;
}
.player-page-header__info-placeholder-inner--team-name {
  width: 70px;
  height: 10px;
  border-radius: 3px;
}
.player-page-header__info-placeholder-inner--name {
  margin-top: 12px;
  margin-bottom: 19px;
  width: 114px;
  height: 23px;
  border-radius: 3px;
}
.player-page-header__info-placeholder-inner--position-name {
  width: 133px;
  height: 32px;
  border-radius: 80px;
}
.player-page-header__images {
  position: relative;
  padding-right: 20px;
  z-index: 0;
}
.player-page-header__images .sportsbook-player-image {
  margin: 20px 20px 0 20px;
  width: 157px;
  height: 140px;
  background-color: transparent;
  border-radius: 0;
}
.player-page-header__logo {
  position: absolute;
  opacity: 0.15;
  z-index: -1;
  top: -115px;
  left: -65px;
}
@media (max-width: 480px) {
  .player-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 320px;
  }
  .player-page-header__info {
    padding: 30px 40px 0 40px;
  }
  .player-page-header__info .player-page-header__name {
    font-size: 30px;
    line-height: 34px;
  }
  .player-page-header__images {
    position: relative;
    top: 4px;
    padding-right: 0;
  }
  .player-page-header__images .sportsbook-player-image {
    margin: 0px 20px;
  }
  .player-page-header__logo {
    position: absolute;
    top: -170px;
    left: -88px;
  }
}
/* Milestones variables */
/* Information tooltip */
